import React, { useMemo, useState } from "react";
import DefaultModal from "../DefaultModal";
import LockedContainer from "./locked/LockedContainer";
import ModalPaymentPhone from "./payment_details/ModalPaymentPhone";
import ModalPaymentCard from "./payment_details/ModalPaymentCard";
import StepContainer from "./StepContainer.jsx";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/index.ts";
import "./top-up-balance.scss";

export default function TopUpBalance({ isOpen, closeModal }) {
  const { t } = useTranslation();
  const lockBonusClose = useAppSelector((state) => state.site.lock.balanceClose);
  const currency = useAppSelector((state) => state.user.currency);

  const [lockedInfo, setLockedInfo] = useState(false);
  const [openPaymentPhone, setOpenPaymentPhone] = useState(false);
  const [openPaymentCard, setOpenPaymentCard] = useState(false);

  const title = useMemo(
    () =>
      currency ? t("Top up your balance in", { currency: currency }) : t("Top up your balance"),
    [currency, t]
  );

  return (
    <>
      <DefaultModal
        isOpen={isOpen}
        classModal="top-up-balance"
        closeModal={!lockBonusClose && closeModal}
        hideClose={lockBonusClose}
        miniModal>
        {/* <CloseModal close={closeModal} /> */}
        <LockedContainer locked={lockedInfo?.locked} lockedInfo={lockedInfo}>
          <h2>{title}</h2>

          <StepContainer
            isOpen={isOpen}
            closeModal={closeModal}
            setLockedInfo={setLockedInfo}
            lockedInfo={lockedInfo}
            setOpenPaymentCard={setOpenPaymentCard}
            setOpenPaymentPhone={setOpenPaymentPhone}
          />
        </LockedContainer>
      </DefaultModal>

      <ModalPaymentPhone
        isOpen={!!openPaymentPhone}
        closeModal={() => {
          setOpenPaymentPhone(false);
          closeModal();
        }}
        data={openPaymentPhone}
      />

      <ModalPaymentCard
        isOpen={!!openPaymentCard}
        data={openPaymentCard}
        closeModal={() => {
          setOpenPaymentCard(false);
          closeModal();
        }}
      />
    </>
  );
}
