import React from "react";
import "./game-desk.scss";
import CarouselDesk from "../../../mui/carousel_desk/CarouselDesk";

import GameItem from "../../../mui/game_tem/GameItem";
import ShowContainer from "../../../mui/ShowContainer";
import useGetItems from "../../../../hooks/other/useGetItems";

export default function GameDesk({
  title,
  hideFavorites,
  showAllLink,
  gameNavigate = "game",
  items = [],
  row2
}) {
  const slidesToShow = useGetItems();
  const isRow2 = row2 && items.length > slidesToShow;
  const GameRowClass = isRow2 ? "games-rows-2" : "games-rows";

  const getGameItems = () => {
    if (!isRow2) return items.map((el) => ({ game1: el }));

    const res = [];
    let curItem = {
      game1: undefined,
      game2: undefined
    };
    items.forEach((el, indx) => {
      indx % 2 ? (curItem.game1 = el) : (curItem.game2 = el);

      if (curItem.game1 && curItem.game2) {
        res.push(curItem);
        curItem = {};
      }
    });
    return res;
  };

  const gameItems = getGameItems();

  return (
    <ShowContainer condition={Array.isArray(items) && items.length}>
      <CarouselDesk isRow2 id={"game-desk"} title={title} items={items} showAllLink={showAllLink}>
        {gameItems.map((el, indx) => {
          const game1 = el.game1;
          const game2 = el.game2;

          return (
            <div key={el.game_id || el.id || indx} className={GameRowClass}>
              <GameItem
                currentGame={game1}
                image={game1.image}
                author={game1.author}
                name={game1.name}
                hideFavorites={hideFavorites}
                url={gameNavigate + "/"}
              />

              <ShowContainer condition={!!game2}>
                <GameItem
                  currentGame={game2}
                  image={game2?.image}
                  author={game2?.author}
                  name={game2?.name}
                  hideFavorites={hideFavorites}
                  url={gameNavigate + "/"}
                />
              </ShowContainer>
            </div>
          );
        })}
      </CarouselDesk>
    </ShowContainer>
  );
}
