import { useNavigate, useLocation } from "react-router-dom";

const useNavigateWithSearch = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (path, options) => {
    const searchParams = location.search;
    const hashParams = location.hash;
    navigate(`${path}${searchParams}${hashParams}`, options);
  };
};

export default useNavigateWithSearch;
