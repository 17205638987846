import React from "react";
import "./locked-container.scss";
import { useTranslation } from "react-i18next";
import WarningInfo from "../../../mui/warning_info/WarningInfo";
import ShowContainer from "../../../mui/ShowContainer";
import { useAppSelector } from "../../../../store/index.ts";
import { useReadLocalStorage } from "usehooks-ts";
import { ENUM_LCS_NAME } from "../../../../store/enums";

export default function LockedContainer({ locked, lockedInfo = {}, children }) {
  const { t } = useTranslation();
  const start_r = useReadLocalStorage(ENUM_LCS_NAME.startR) || "";
  const supportChat = useAppSelector((state) => state.links.supportChat);

  const onSupportClick = () => {
    const supportLink = start_r
      ? `https://t.me/${supportChat}?start_r=${start_r}`
      : `https://t.me/${supportChat}`;
    window.open(supportLink, "_blank");
  };

  const time = lockedInfo.time;
  // const time = {
  //   days: 1,
  //   hours: 15,
  //   minutes: 12
  // };

  return locked ? (
    <div className="locked-container">
      <h2>{t("The ability to top up your balance is temporarily suspended!")}</h2>

      <ShowContainer condition={time}>
        <h3>{t("Time remaining until the blocking ends")}</h3>

        <div className="time-container">
          <div className="time-container__days">
            <span className="name">{t("Days -")}</span>
            <span className="value">{time?.days}</span>
          </div>
          <div className="time-container__horus">
            <span className="name">{t("Hours -")}</span>
            <span className="value">{time?.hours}</span>
          </div>
          <div className="time-container__minutes">
            <span className="name">{t("Minutes -")}</span>
            <span className="value">{time?.minutes}</span>
          </div>
        </div>
      </ShowContainer>

      <WarningInfo onClick={onSupportClick}>
        <p>
          {t("To clarify the reason, contact support!")} @{supportChat}
        </p>
      </WarningInfo>
    </div>
  ) : (
    <>{children}</>
  );
}
