import { useEffect } from "react";
import { getParamFromUrl } from "../utils/utils";
import { useDispatch } from "react-redux";
import { setHardModeGame, setSessionId } from "../store/commonSlice";
import useFillCasino from "./common/useFillCasino";
import useNavigateWithSearch from "./useNavigateWitchSearch";
import useDeviceSizeFill from "./useDeviceSizeFill";
import { useLocalStorage } from "usehooks-ts";
import useMainPage from "./common/useMainPage";
import useFillLinks from "./common/useFillLinks";
import useListener from "./common/useListener";
import useJackpotInterval from "./common/useJackpotInterval";
import useResizeListener from "./common/useResizeListener";
import useUrlParc from "./other/useUrlParc";
import { ENUM_LCS_NAME } from "../store/enums";
import useTawkController from "./other/useTawkController";
import useModalShowLogic from "./common/useModalShowLogic";
import { useLocation } from "react-router-dom";
import useGetLink from "./other/useGetLink";
import useNewPasCheck from "./common/useNewPasCheck";
import useFillUser from "./useFillUser";

// useCommonHooks - основные хуки для
/**
  @description  основные небольшие хуки 
 */

export default function useCommonHooks() {
  useFillCasino();
  useDeviceSizeFill();
  useListener();
  useFillLinks();
  useJackpotInterval();
  useMainPage();
  useResizeListener();
  useUrlParc();
  useTawkController();
  useModalShowLogic();
  useNewPasCheck();
  useFillUser();

  const [saveGame, setSaveGame] = useLocalStorage(ENUM_LCS_NAME.saveGame);
  const navigate = useNavigateWithSearch();
  const dispatch = useDispatch();
  const location = useLocation();
  const newPath = useGetLink(location.pathname);

  useEffect(() => {
    const sessionId = getParamFromUrl("sessionId");
    const mode = getParamFromUrl("mode");

    if (sessionId) dispatch(setSessionId(sessionId));
    if (mode) dispatch(setHardModeGame(mode));
  }, [window.location.search]);

  useEffect(() => {
    const path = saveGame?.path;
    if (!path) return;
    if (window.location.pathname == path) return;
    navigate(path);
    console.log("path", path);
    setSaveGame({});
  }, [navigate, saveGame]);

  useEffect(() => {
    window.history.pushState("", "", newPath);
    // console.log(newPath);
  }, [newPath]);

  // useEffect(() => {
  //   if (isTgBot) return;
  //   dispatch(setModeGame(isAuth ? ENUM_MODE_GAME.real : ENUM_MODE_GAME.demo));
  // }, [isAuth, isTgBot]);
}
