import React from "react";
import "./custom-checkbox.scss";

export default function CustomCheckbox({ checked, id, defaultChecked }) {
  return (
    <input
      defaultChecked={defaultChecked}
      type="checkbox"
      className="custom-checkbox"
      checked={checked}
      id={id}
    />
  );
}
