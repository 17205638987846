import React, { useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ShowContainer from "../ShowContainer";
import IconArrow from "../JSXIcons/IconArrow";
import "../chose_lang/chose-lang.scss";
import { setCurrency } from "../../../store/userSlice";
import { API_USER } from "../../../api/user";
import { TEST_MODE } from "../../../store/enums";
import { checkObject } from "../../../utils/utils";
import "./choose-currency.scss";
import CusButton from "../custom_button/CusButton";
import IconCirclePlus from "../JSXIcons/IconCirclePlus";
import { setModalBalance, setPromoCode, setWithdrawal } from "../../../store/siteSlice";
import IconCircleMinus from "../JSXIcons/IconCircleMinus";
import { useTranslation } from "react-i18next";

export default function ChooseCurrency({ classNameUl = "", isFullVariant }) {
  const chooseLangRef = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currency = useSelector((state) => state.user.currency);
  const balance = useSelector((state) => state.user.balance);

  const getMainBalance = (currency) => {
    const mainBalance = balance[currency]?.balances?.main;
    const decimal = balance[currency]?.info?.decimal || 0;
    return mainBalance?.toFixed(decimal);
  };
  const getBonusBalance = (currency) => {
    const bonusBalance = balance[currency]?.balances?.bonus;
    const decimal = balance[currency]?.info?.decimal || 0;

    return bonusBalance?.toFixed(decimal);
  };

  const currencies = useMemo(() => (checkObject(balance) ? Object.keys(balance) : []), [balance]);

  const getSelect = (val) => (val === currency ? "active" : "");

  const changeCurrency = (currency) => {
    if (TEST_MODE) {
      console.log(currency);
      dispatch(setCurrency(currency));
      return;
    }

    API_USER.changeCurrency({ currency })
      .then((e) => {
        console.log("res change cur", e);
        const data = e.data;
        dispatch(setCurrency(data.currency));
      })
      .catch(console.error);
  };

  const bonusBalance = +getBonusBalance(currency);

  return (
    <ShowContainer condition={currencies?.length}>
      <div className="choose-currency-container">
        <div className="choose-currency__wrapper">
          <span className="choose-currency__gradient" />
          <button
            className="choose-currency"
            onMouseOver={() => chooseLangRef.current.classList.remove("close")}>
            <span className="choose-currency__active">
              <span className="balance-value">
                <span className="main">{getMainBalance(currency)}</span>
                {!!bonusBalance && <span className="bonus">{bonusBalance}</span>}
              </span>
              <span className="currency">{currency}</span>
              <IconArrow className="choose-currency__arrow" />
            </span>
          </button>
        </div>

        <div className="choose-currency__plug">
          <ul ref={chooseLangRef} className={classNameUl}>
            {currencies.map((el, indx) => {
              const bonusBalance = getBonusBalance(el);

              return (
                <li
                  key={indx}
                  onClick={() => {
                    changeCurrency(el);
                    chooseLangRef.current.classList.add("close");
                  }}>
                  <button className={`currency-item ${getSelect(el)}`}>
                    <div className="main">
                      <span className="balance-value">
                        <span className="main">{getMainBalance(el)}</span>
                      </span>
                      <span className="currency">{el}</span>
                    </div>
                    <ShowContainer condition={!!+bonusBalance}>
                      <div className="bonus">
                        <span className="bonus">
                          {t("bonus: ")} {bonusBalance}
                        </span>
                      </div>
                    </ShowContainer>
                  </button>
                </li>
              );
            })}

            <ShowContainer condition={isFullVariant}>
              <li className="controls-btn">
                <CusButton
                  color="gradient-blue"
                  icon={<IconCirclePlus />}
                  className="plus"
                  border
                  onClick={() => dispatch(setModalBalance(true))}
                />
                <CusButton
                  icon={<IconCircleMinus />}
                  className="minus"
                  border
                  onClick={() => dispatch(setWithdrawal(true))}
                />
              </li>
              <li className="controls-promo">
                <CusButton text={"Promo codes"} onClick={() => dispatch(setPromoCode(true))} />
              </li>
            </ShowContainer>
          </ul>
        </div>

        <ShowContainer condition={isFullVariant}>
          <CusButton
            color="gradient-blue"
            icon={<IconCirclePlus />}
            className="replenish"
            border
            onClick={() => dispatch(setModalBalance(true))}
          />
        </ShowContainer>
      </div>
    </ShowContainer>
  );
}
