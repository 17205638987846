import React from "react";
import CustomCheckbox from "../custom_checkbox/CustomCheckbox";
import { useTranslation } from "react-i18next";
import "./checkbox-info.scss";

export default function CheckboxInfo({ text, id = Math.random(), defaultChecked }) {
  const { t } = useTranslation();
  return (
    <div className="checkbox-info">
      <CustomCheckbox id={id} defaultChecked={defaultChecked} />
      <label htmlFor={id}>{typeof text === "string" ? t(text) : text}</label>
    </div>
  );
}
