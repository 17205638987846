import React from "react";
import "./profile-modal.scss";
import DefaultModal from "../DefaultModal";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/index.ts";
import { ENUM_ACCOUNT_REGISTER } from "../../../store/enums.js";
import ShowContainer from "../../mui/ShowContainer.jsx";

import ProfileNewEmail from "./ProfileNewEmail.jsx";
import ProfileNewPas from "./ProfileNewPas.jsx";

export default function ProfileModal({ isOpen, closeModal }) {
  const { t } = useTranslation();
  const regType = useAppSelector((state) => state.user.info.regType);
  const isRegisterEmail = regType === ENUM_ACCOUNT_REGISTER.email;

  return (
    <DefaultModal miniModal isOpen={isOpen} closeModal={closeModal} classModal="profile-modal">
      <h2>{t("Personal information")}</h2>
      <ShowContainer condition={isRegisterEmail}>
        <ProfileNewEmail closeModal={closeModal} />

        <ProfileNewPas closeModal={closeModal} />
      </ShowContainer>
    </DefaultModal>
  );
}
