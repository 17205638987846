import React, { useMemo } from "react";
import "./jackpot.scss";
import { useTranslation } from "react-i18next";
import IconStarFilledThin from "../../mui/JSXIcons/IconStarFilledThin";
import CircleInfo from "../../mui/circle-info/CircleInfo";
import IconStarSimpleThin from "../../mui/JSXIcons/IconStarSimpleThin";
import { useAppSelector } from "../../../store/index.ts";
import ShowContainer from "../../mui/ShowContainer.jsx";

export default function Jackpot() {
  const { t } = useTranslation();
  const jackpot = useAppSelector((state) => state.promotions.jackpots);
  const balance = useAppSelector((state) => state.user.balance);

  const jackpotEmpty = !(jackpot?.grand || jackpot?.major || jackpot?.mini);

  const currency = jackpot?.currency;
  const grand = jackpot?.grand;
  const major = jackpot?.major;
  const mini = jackpot?.mini;
  const decimal = useMemo(() => balance[currency]?.info?.decimal, [balance, currency]);
  const totalJackpotBalanceAmount = grand?.amount + major?.amount + mini?.amount || 0;
  const totalJackpotBalance = totalJackpotBalanceAmount.toFixed(decimal);

  const cards = useMemo(() => {
    const grandCard = {
      title: "Grand Jackpot",
      dop: t("the minimum total bet in the game to qualify for this jackpot.", {
        minBet: grand?.minBet,
        currency: currency
      }),
      balance: grand?.amount
    };

    const majorCard = {
      title: "Major Jackpot",
      dop: t("the minimum total bet in the game to qualify for this jackpot.", {
        minBet: major?.minBet,
        currency: currency
      }),
      balance: major?.amount
    };

    const miniCard = {
      title: "Mini Jackpot",
      dop: t("the minimum total bet in the game to qualify for this jackpot.", {
        minBet: mini?.minBet,
        currency: currency
      }),
      balance: mini?.amount
    };

    const cards = [];

    if (grand?.amount) cards.push(grandCard);
    if (major?.amount) cards.push(majorCard);
    if (mini?.amount) cards.push(miniCard);

    return cards;
  }, [jackpot, currency]);

  return (
    <ShowContainer condition={!jackpotEmpty && +totalJackpotBalance}>
      <div className="jackpot">
        <div className="jackpot__main">
          <span className="jackpot__main__title">
            {t("Total jackpot")} <IconStarFilledThin />
          </span>
          <span className="jackpot__main__balance">
            <span className="jackpot__main__balance__value">{totalJackpotBalance}</span>
            <span className="jackpot__main__balance__currency">{currency}</span>
            <IconStarSimpleThin />
          </span>
        </div>
        <div className="jackpot__cards">
          {cards.map((card, indx) => (
            <div key={indx} className="jackpot__card">
              <span className="jackpot__card__title">
                {card.title}
                <CircleInfo info={card.dop} />
              </span>
              <span className="jackpot__card__balance">
                <span className="jackpot__card__balance__value">{card.balance}</span>
                <span className="jackpot__card__balance__current">{currency}</span>
              </span>
            </div>
          ))}
        </div>
      </div>
    </ShowContainer>
  );
}
