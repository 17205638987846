import { useAppSelector } from "../../store/index.ts";
import useCurrentBalance from "../useCurrentBalance.js";
import { useEventListener, useReadLocalStorage } from "usehooks-ts";
import { setModalBalance, setModalLogin, setTypeLogin } from "../../store/siteSlice.js";
import { useDispatch } from "react-redux";
import { ENUM_LCS_NAME, ENUM_LOGIN } from "../../store/enums.js";
import { useEffect, useRef } from "react";
import { dispatchCusEvent } from "../../utils/utils.js";

export const DELAY_TM_1 = 60 * 1000;
export const DELAY_TM_2 = 90 * 1000;

/**
 * что бы лишний раз не мучаться с логикой таймаутов в реакте,
 * сделано через кастомные события
 */
export default function useModalShowLogic() {
  const dispatch = useDispatch();
  const alreadyAccount = useReadLocalStorage(ENUM_LCS_NAME.alreadyAccount);
  const userId = useAppSelector((state) => state.user.userId);
  const isTgBot = useAppSelector((state) => state.common.isTgBot);
  const openLogin = useAppSelector((state) => state.site.modals.login);
  const isShownNewPas = useAppSelector((state) => state.site.modals.shownNewPas);

  const { balances } = useCurrentBalance();
  const tmRef = useRef();

  const checkOpenModal = () => {
    if (isTgBot) return;
    if (isShownNewPas) return;

    if (userId) {
      if (balances.main) return;

      dispatch(setModalBalance(true));
      return;
    }

    // модалка регистрации уже открыта
    if (openLogin) return;

    console.log("alreadyAccount", alreadyAccount);

    dispatch(setTypeLogin(alreadyAccount ? ENUM_LOGIN.login : ENUM_LOGIN.register));
    dispatch(setModalLogin(true));

    clearTimeout(tmRef.current);

    tmRef.current = setTimeout(() => {
      dispatchCusEvent(ENUM_LCS_NAME.checkOpenModal);
    }, DELAY_TM_1);
  };

  useEffect(() => {
    if (tmRef.current) {
      clearTimeout(tmRef.current);
      tmRef.current = setTimeout(() => {
        dispatchCusEvent(ENUM_LCS_NAME.checkOpenModal);
      }, DELAY_TM_2);
      return;
    }

    tmRef.current = setTimeout(() => {
      dispatchCusEvent(ENUM_LCS_NAME.checkOpenModal);
    }, DELAY_TM_1);

    return () => clearTimeout(tmRef.current);
  }, []);

  useEventListener(ENUM_LCS_NAME.checkOpenModal, () => checkOpenModal());
}
