import React, { useState } from "react";
import IconLightningCircle from "../../mui/JSXIcons/IconLightningCircle";
import CusButton from "../../mui/custom_button/CusButton";
import { useTranslation } from "react-i18next";
import Back from "../../mui/back/Back";
import ShowContainer from "../../mui/ShowContainer";
import { useSelector } from "react-redux";
import { useReadLocalStorage } from "usehooks-ts";
import { ENUM_LCS_NAME } from "../../../store/enums";

export default function StaticBonusItem({ bonus }) {
  const { t } = useTranslation();
  const start_r = useReadLocalStorage(ENUM_LCS_NAME.startR) || "";
  const botName = useSelector((state) => state.common.botName);
  const [step, setStep] = useState(0);

  const open = bonus.open;
  const openClass = step ? "" : "hidden-bonus";

  const onGetBonus = () => {
    bonus.onClick();
  };

  const hrefTg = start_r ? `https://t.me/${botName}?start_r=${start_r}` : `https://t.me/${botName}`;

  return (
    <div key={bonus.id} className={`bonus ${bonus.className}`}>
      <button className="bonus__light" onClick={() => (open ? setStep(1) : bonus.onClick())}>
        <IconLightningCircle />
      </button>
      <div className="bonus__lightning"></div>
      <h4>{t(bonus.title)}</h4>
      <img src={bonus.img} />
      <CusButton
        className="bonus__terms"
        text={bonus.btnText}
        onClick={() => (open ? setStep(1) : bonus.onClick())}
      />

      <ShowContainer condition={open}>
        <div className={`bonus__info ${openClass}`}>
          <Back onClick={() => setStep(0)} />

          <h3>{open?.title}</h3>
          <p>{open?.text}</p>
          {open?.isTgWidget ? (
            <ShowContainer condition={botName}>
              <div className="bonus__info__btn-container">
                <a className="custom-button" href={hrefTg} target="_blank" rel="noreferrer">
                  {t("Go to bot and get a bonus")}
                </a>
              </div>
            </ShowContainer>
          ) : (
            <CusButton text={"Get bonus"} onClick={onGetBonus} />
          )}
        </div>
      </ShowContainer>
    </div>
  );
}
