import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import GameContainer from "../components/games/game_container/GameContainer";
import ShowContainer from "../components/mui/ShowContainer";
import { getUuid } from "../utils/utils";
import { useReadLocalStorage } from "usehooks-ts";
import useCheckEmptyPage from "../hooks/useCheckEmptyPage";
import { ENUM_LCS_NAME } from "../store/enums";

export default function BonusGame() {
  const { id } = useParams();
  const games = useSelector((state) => state.games.bonusGames);
  const saveGame = useReadLocalStorage(ENUM_LCS_NAME.saveGame);

  const currentGame = useMemo(() => games.find((el) => el.id == id), [id, games]);
  const newUid = useMemo(() => saveGame?.uid || getUuid(), [saveGame]);

  useCheckEmptyPage({ isEmpry: currentGame });

  return (
    <ShowContainer condition={currentGame}>
      <GameContainer
        gameId={currentGame?.id}
        currentGame={currentGame}
        isBonus={true}
        uid={newUid}
      />
    </ShowContainer>
  );
}
