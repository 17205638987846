import React, { lazy, Suspense, useEffect, useMemo } from "react";
import Logo from "../header/logo/Logo";
import FindGame from "../find_game/FindGame";
import "./aside-menu.scss";
import MenuProviders from "./menu_providers/MenuProviders";
import IconClip from "../../mui/JSXIcons/IconClip";
import { useTranslation } from "react-i18next";
import IconTg from "../../mui/JSXIcons/IconTg";
import IconSupport from "../../mui/JSXIcons/IconSupport";
import BonusBannerMenu from "./bonus_menu/BonusBannerMenu";
import ShowContainer from "../../mui/ShowContainer";
import { useAppSelector } from "../../../store/index.ts";
import ErrorBoundary from "../../mui/error_boundary/ErrorBoundary.jsx";
import { useReadLocalStorage } from "usehooks-ts";
import { useLocation } from "react-router-dom";
import { ENUM_LCS_NAME } from "../../../store/enums.js";

const TgWidgetContainer = lazy(() => import("./TgWidgetContainer"));

export default function AsideMenu({ className = "" }) {
  const { t } = useTranslation();
  const start_r = useReadLocalStorage(ENUM_LCS_NAME.startR) || "";
  const location = useLocation();
  const pathname = useMemo(() => location.pathname, [location]);

  const botName = useAppSelector((state) => state.common.botName);
  const supportChat = useAppSelector((state) => state.links.supportChat);
  const openMenu = useAppSelector((state) => state.site.openMenu);
  const isMenuAbsolute = useAppSelector((state) => state.deviceSize.isMenuAbsolute);
  const channelUrl = useAppSelector((state) => state.links.channelUrl);
  const tgPostUrl = useAppSelector((state) => state.links.tgPostUrl);
  const pageWitchMenuHeight = useAppSelector((state) => state.site.pageWitchMenuHeight);
  const isIos = useAppSelector((state) => state.deviceSize.isIos);
  const isWebApps = useAppSelector((state) => state.deviceSize.isWebApps);

  const isDark = true;
  const idAsideMenu = isMenuAbsolute ? "aside-absolute" : "aside-static";
  // const clientHeight = document.body.clientHeight || document.body.scrollHeight;

  useEffect(() => {
    // if (isMenuAbsolute) return;

    const innerHeight = isMenuAbsolute ? window.innerHeight : Infinity;
    const maxHeight = Math.min(pageWitchMenuHeight, innerHeight);

    const aside = document.getElementById(idAsideMenu);
    const asideMenu = aside.querySelector("#aside-menu__menu");
    const asideMenuBox = asideMenu.getBoundingClientRect();

    asideMenu.style.maxHeight = `${maxHeight - asideMenuBox.top}px`;
  }, [openMenu, isMenuAbsolute, pageWitchMenuHeight, pathname]);

  const openClass = openMenu ? "open" : "";
  const absoluteClass = isMenuAbsolute ? "fixed" : "";

  const onCasinoClick = () => {
    const casinoLink = start_r
      ? `https://t.me/${botName}?start_r=${start_r}`
      : `https://t.me/${botName}`;
    window.open(casinoLink, "_blank");
  };
  const onSupportClick = () => {
    const supportLink = start_r
      ? `https://t.me/${supportChat}?start_r=${start_r}`
      : `https://t.me/${supportChat}`;
    window.open(supportLink, "_blank");
  };

  return (
    <>
      <ShowContainer condition={isMenuAbsolute && openMenu}>
        <div className="aside-menu aside-menu__plug"></div>
      </ShowContainer>
      <aside id={idAsideMenu} className={`aside-menu ${className} ${openClass} ${absoluteClass}`}>
        <Logo showOpenMenu={true} />
        <div id="aside-menu__menu" className="aside-menu__menu">
          <div className="menu-content">
            <ErrorBoundary>
              <BonusBannerMenu />
            </ErrorBoundary>
            <ErrorBoundary>
              <FindGame />
            </ErrorBoundary>
            <ErrorBoundary>
              <MenuProviders />
            </ErrorBoundary>
            <div className="menu__other">
              <h2>
                <IconClip /> {t("Other")}
              </h2>
              <div>
                <ShowContainer condition={botName}>
                  <button className="menu__other__item" onClick={onCasinoClick}>
                    <IconTg />
                    <span className="menu__other__item__title">{t("Telegram Casino")}</span>
                  </button>
                </ShowContainer>

                <ShowContainer condition={supportChat}>
                  <button className="menu__other__item" onClick={onSupportClick}>
                    <IconSupport />
                    <span className="menu__other__item__title">{t("Support")}</span>
                  </button>
                </ShowContainer>
              </div>
            </div>
            {/* <SocialIcons /> */}
          </div>

          <ShowContainer condition={channelUrl}>
            <a
              href={channelUrl}
              target="_blank"
              rel="noreferrer"
              className="tg-group"
              style={{ background: isDark ? "#121212" : undefined }}>
              {t("Telegram group")}
            </a>
          </ShowContainer>

          <ShowContainer condition={tgPostUrl && !(isIos && isWebApps)}>
            <ErrorBoundary>
              <Suspense>
                <TgWidgetContainer />
              </Suspense>
            </ErrorBoundary>
          </ShowContainer>
        </div>
      </aside>
    </>
  );
}
