import React from "react";
import "./banner-item.scss";
import { useDispatch, useSelector } from "react-redux";
import useNavigateWithSearch from "../../../../hooks/useNavigateWitchSearch";
import { setBannerPosition } from "../../../../store/siteSlice";

const ENUM_GAME_TYPE = {
  INTEGRATED: 1,
  BONUS: 2
};

const videoTypes = [".webm", ".avi", ".mp4", ".ogg"];

export default function BannerItem({
  id,
  gameId,
  gameType,
  fileUrl,
  mobileFileUrl,
  externalUrl,
  relativeUrl,
  position
}) {
  const navigate = useNavigateWithSearch();
  const dispatch = useDispatch();
  const isExtraSmall = useSelector((state) => state.deviceSize.isExtraSmall);
  const currentUrl = isExtraSmall ? mobileFileUrl || fileUrl : fileUrl;

  const isVideo = videoTypes.some((el) => currentUrl.includes(el));

  const onClick = () => {
    const getUrl = () => {
      if (gameId && gameType) {
        if (gameType == ENUM_GAME_TYPE.INTEGRATED) return "/game/" + gameId;
        if (gameType == ENUM_GAME_TYPE.BONUS) return "/bonus_game/" + gameId;
      }
    };

    const url = getUrl();
    if (url) return navigate(url);

    if (relativeUrl) {
      dispatch(setBannerPosition(position));
      return navigate("/banner/" + relativeUrl);
    }
    if (externalUrl) return window.open(externalUrl, "_blank");
  };

  return (
    <div className="banner-item" id={id} onClick={onClick}>
      <div className="banner-item__content">
        {isVideo ? (
          <video className="banner-item__video" src={currentUrl} autoPlay loop playsInline muted />
        ) : (
          <img className="banner-item__img" src={currentUrl} />
        )}
      </div>
    </div>
  );
}
