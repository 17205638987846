import React, { useEffect } from "react";
import present from "./present.png";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "./top-up-balance-bonus.scss";
import { useAppSelector } from "../../../../store/index.ts";
import ShowContainer from "../../../mui/ShowContainer.jsx";
import { API_CASINO } from "../../../../api/casino";
import { useReadLocalStorage } from "usehooks-ts";
import {
  setCashback,
  setFirstDeposit,
  setForUser,
  setRegistration
} from "../../../../store/promotionsSlice.js";
import { ENUM_LCS_NAME, TEST_MODE } from "../../../../store/enums.js";

export default function TopUpBonus() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const start_r = useReadLocalStorage(ENUM_LCS_NAME.startR) || "";
  const currency = useSelector((state) => state.user.currency);
  const userId = useAppSelector((state) => state.user.userId);
  const firstDep = useAppSelector((state) => state.promotions.firstDeposit);
  const cashback = useAppSelector((state) => state.promotions.cashback);
  const forUserBonus = useAppSelector((state) => state.promotions.forUser);

  const bonusCashback = cashback?.values?.percent;
  const minCashback = cashback?.values?.minDeposit;

  const bonusWager = firstDep?.values?.wager;
  const minFirst = firstDep?.values?.minDeposit;
  const maxFirst = firstDep?.values?.amount;

  const min = minFirst ?? minCashback;

  useEffect(() => {
    if (TEST_MODE) {
      const testDep = { values: { amount: 2000, wager: 2, cashout: 3, minDeposit: 301 } };
      const testReg = { values: { amount: 500, wager: 2, cashout: 3 } };

      dispatch(setCashback(4));
      dispatch(setFirstDeposit(testDep));
      dispatch(setRegistration(testReg));
      dispatch(setForUser(userId));
      return;
    }
    if (userId === forUserBonus) return;

    API_CASINO.getBonuses({ start_r })
      .then((e) => {
        const data = e.data;
        dispatch(setCashback(data.cashback));
        dispatch(setFirstDeposit(data.firstDeposit));
        dispatch(setRegistration(data.registration));
        dispatch(setForUser(userId));
      })
      .catch((e) => {
        console.log(e);
      });
  }, [userId, forUserBonus, start_r]);

  return (
    <ShowContainer condition={bonusCashback || bonusWager}>
      <div className="top-up-balance__bonus">
        <div className="top-up-balance__bonus__info">
          <span className="top-up-balance__bonus__info__item">
            <span>
              {t("Top up from")} {min}
            </span>
            <span className="currency">{currency}</span>
          </span>

          {bonusWager ? (
            <div className="top-up-balance__bonus__info__item column">
              <span>{t("Get double the amount!")}</span>

              <div className="bonus-container">
                <span className="bonus">
                  {t("to_max_cur", {
                    max: maxFirst,
                    cur: currency
                  })}
                </span>
              </div>
            </div>
          ) : (
            <div className="top-up-balance__bonus__info__item">
              <span>{t("Get an extra bonus!")}</span>
              <div className="bonus-container">
                <span className="bonus">+{bonusCashback}%</span>
              </div>
            </div>
          )}
        </div>
        <img src={present} />
      </div>
    </ShowContainer>
  );
}
