import { useInterval, useReadLocalStorage } from "usehooks-ts";
import { ENUM_LCS_NAME, TEST_MODE } from "../../store/enums";
import { useDispatch, useSelector } from "react-redux";
import { setJackpots } from "../../store/promotionsSlice";
import { testJackpots } from "../../store/testConst";
import { API_CASINO } from "../../api/casino";
import { setOpenJackpot } from "../../store/siteSlice";
import { useEffect, useRef } from "react";
import { ENUM_COMMON_EVENT } from "./useListener";
import { dispatchCusEvent } from "../../utils/utils";

const DELAY_JACKPOT = 10 * 1000;

export default function useJackpotInterval() {
  const start_r = useReadLocalStorage(ENUM_LCS_NAME.startR) || "";
  const currency = useSelector((state) => state.user.currency);

  const dispatch = useDispatch();
  const awaitJack = useRef(false);

  const getJackpot = () => {
    if (awaitJack.current) return;
    awaitJack.current = true;
    API_CASINO.getJackpots({ start_r })
      .then((e) => {
        awaitJack.current = false;
        if (!e.data.list) return;
        const list = e.data.list;
        dispatch(setJackpots(list));

        for (let type in list) {
          const curInfo = list[type];

          if (type === "currency") continue;
          const currency = list.currency;
          const jackpotData = { type, currency, amount: curInfo.amount };

          if (curInfo.drop) {
            dispatch(setOpenJackpot(jackpotData));
            dispatchCusEvent(ENUM_COMMON_EVENT.getBalance);
          }
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    if (TEST_MODE) {
      dispatch(setJackpots(testJackpots.list));
      return;
    }
    getJackpot();
  }, [currency]);

  useInterval(() => {
    if (TEST_MODE) return;

    getJackpot();
  }, DELAY_JACKPOT);
}
