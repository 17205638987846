import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

const DynamicHead = ({ content }) => {
  useEffect(() => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = content;

    // Handle script elements separately
    const scripts = Array.from(tempDiv.querySelectorAll("script"));
    scripts.forEach((script) => {
      const newScript = document.createElement("script");
      if (script.src) {
        newScript.src = script.src;
      } else {
        newScript.textContent = script.innerHTML;
      }
      document.head.appendChild(newScript);
    });

    // Remove script elements from tempDiv
    scripts.forEach((script) => script.parentNode.removeChild(script));

    // Append the remaining sanitized content
    document.head.innerHTML += tempDiv.innerHTML;

    // Cleanup
    return () => {
      scripts.forEach((script) => {
        if (script.src) {
          const existingScript = document.querySelector(`script[src="${script.src}"]`);
          if (existingScript) document.head.removeChild(existingScript);
        } else {
          const existingScripts = Array.from(document.querySelectorAll("script")).filter(
            (s) => s.textContent === script.innerHTML
          );
          existingScripts.forEach((s) => document.head.removeChild(s));
        }
      });
      // Remove other appended elements
      Array.from(tempDiv.children).forEach((child) => {
        if (document.head.contains(child)) {
          document.head.removeChild(child);
        }
      });
    };
  }, [content]);

  return null;
};

const DynamicBody = ({ content }) => {
  useEffect(() => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = content;

    const appendedElements = [];

    // Handle script elements separately
    const scripts = Array.from(tempDiv.querySelectorAll("script"));
    scripts.forEach((script) => {
      const newScript = document.createElement("script");
      if (script.src) {
        newScript.src = script.src;
      } else {
        newScript.textContent = script.innerHTML;
      }
      document.body.appendChild(newScript);
      appendedElements.push(newScript);
    });

    // Remove script elements from tempDiv
    scripts.forEach((script) => script.parentNode.removeChild(script));

    // Append the remaining sanitized content before </body>
    while (tempDiv.firstChild) {
      const node = tempDiv.firstChild;
      document.body.insertBefore(node, document.body.lastChild);
      appendedElements.push(node);
    }

    // Cleanup
    return () => {
      appendedElements.forEach((element) => {
        if (document.body.contains(element)) {
          document.body.removeChild(element);
        }
      });
    };
  }, [content]);

  return null;
};

export default function Head({ title, description, favicon, appleTouchIcon }) {
  const lang = useSelector((state) => state.common.lang);
  const htmlHead = useSelector((state) => state.common.htmlHead);
  const htmlEndBody = useSelector((state) => state.common.htmlEndBody);
  const isTgBot = useSelector((state) => state.common.isTgBot);
  const liveChatJS = useSelector((state) => state.common.liveChatJS);

  useEffect(() => {
    if (isTgBot) {
      const script = document.createElement("script");
      script.src = "https://telegram.org/js/telegram-web-app.js";
      script.async = true;
      script.onload = () => {
        if (window.Telegram && window.Telegram.WebApp) {
          const WebApp = window.Telegram.WebApp;
          WebApp.expand();
          WebApp.enableClosingConfirmation();
          console.log("window.Telegram?.WebApp", WebApp);
        }
      };
      script.onerror = () => {
        console.error("Failed to load the Telegram Web App script");
      };
      document.head.appendChild(script);
      return;
    }

    if (liveChatJS) {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = liveChatJS;
      const scripts = Array.from(tempDiv.querySelectorAll("script"));
      scripts.forEach((script) => {
        const newScript = document.createElement("script");
        if (script.src) {
          newScript.src = script.src;
        } else {
          newScript.textContent = script.innerHTML;
        }
        document.body.appendChild(newScript);
      });
      scripts.forEach((script) => script.parentNode.removeChild(script));
    }
  }, [isTgBot, liveChatJS]);

  return (
    <>
      <Helmet htmlAttributes={{ lang: lang }}>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="icon" href={favicon} type="image/png" />
        <link rel="apple-touch-icon" href={appleTouchIcon} />
        {liveChatJS}
      </Helmet>
      {htmlHead && <DynamicHead content={htmlHead} />}
      {htmlEndBody && <DynamicBody content={htmlEndBody} />}
    </>
  );
}
