import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEventListener, useLocalStorage } from "usehooks-ts";
import { DEBUG, ENUM_BOT_THEME, ENUM_LCS_NAME, ENUM_MODE_GAME } from "../store/enums";
import useNavigateWithSearch from "./useNavigateWitchSearch";
import useCheckDemoMode from "./useCheckDemoMode";
import { addRunningGamesId } from "../store/gamesSlice";
import { API_CASINO } from "../api/casino";
import useChooseMode from "./useChooseMode";
import { infoToast } from "../components/mui/Toaster";
import { cancelFs, dispatchCusEvent } from "../utils/utils";
import { setModalBalance } from "../store/siteSlice";
import { useTranslation } from "react-i18next";
import { ENUM_COMMON_EVENT } from "./common/useListener";

// NOT WORKING
export default function useRunGame({
  setOpenLogin,
  setJustCame,
  setErrorMes,
  currentGame,
  uid,
  isBonus,
  gameId,
  justCame
}) {
  const dispatch = useDispatch();
  const navigate = useNavigateWithSearch();
  const { checkIsModeGame } = useChooseMode();
  const { t } = useTranslation();

  checkIsModeGame({
    close: (e) => {
      if (e === true) return;
      onCloseGame();
    },
    game: currentGame
  });
  const mode = useSelector((state) => state.common.modeGame);
  const { errorMes: errorMessage } = useCheckDemoMode({ currentGame, mode });

  const theme = useSelector((state) => state.common.botTheme);
  const saveSite = useSelector((state) => state.common.saveSiteBeforeGame);
  const language = useSelector((state) => state.common.lang);
  const madeOn = useSelector((state) => state.common.madeOn);
  const isAuth = useSelector((state) => state.user.isAuth);

  const [, setSaveGame] = useLocalStorage(ENUM_LCS_NAME.saveGame);
  const [hideCross, setHideCross] = useState(theme === ENUM_BOT_THEME.telegram);
  const [iframeSrc, setIframeSrc] = useState("");

  window.onpopstate = function () {
    setSaveGame({});
    navigate(saveSite.url || "/");
  };

  window.onbeforeunload = function () {
    const pathname = window.location.pathname;
    const isGame = pathname.search("/game/") >= 0 || pathname.search("/bonus_game/") >= 0;
    if (isGame) {
      console.log("isGame", isGame, uid);
      setSaveGame({ uid, path: window.location.pathname });
      return "Changes made may be lost";
    }
  };

  const onCloseGame = () => {
    setSaveGame({});
    setJustCame(false);
    navigate(saveSite.url || "/");
    setHideCross(theme === ENUM_BOT_THEME.telegram);
    // setTimeout(() => window.scrollTo({ top: saveSite.topScroll, left: 0, behavior: "smooth" }), 1);
    setTimeout(() => window.scrollTo({ top: saveSite.topScroll, left: 0 }), 1);
    dispatchCusEvent(ENUM_COMMON_EVENT.getBalance);
  };

  useEventListener("message", (e) => {
    console.log("message", e, e.data);
    // if (e.event) console.log(e);
    if (e.data === "close-frame" || e.data === "closeFrame" || e.message === "close-iframe") {
      onCloseGame();
    }

    const event = e.data.event;

    // dialogs.login
    if (event === "dialogs.login") {
      if (mode === ENUM_MODE_GAME.demo) {
        if (isAuth) return infoToast(t("You are already logged in"));
      }
      setOpenLogin(true);

      const closeOnCancel = e.data?.payload?.closeOnCancel;
      console.log("closeOnCancel", closeOnCancel);
      // if (closeOnCancel) onCloseGame();
    }

    // pages.logout
    if (event === "pages.logout") {
      cancelFs();
      dispatchCusEvent(ENUM_COMMON_EVENT.exit);
      onCloseGame();
    }

    //dialogs.replenish
    if (event === "dialogs.replenish") {
      cancelFs();
      dispatch(setModalBalance(true));
    }
  });

  useEffect(() => {
    if (errorMessage) return setErrorMes(errorMessage);
    if (!mode) return;

    const resGame = (e, isBonus) => {
      if (DEBUG) console.log("start game res", e);
      const data = e.data || {};
      const errorMes = data?.error?.Error || data?.error;
      if (data.redirect) {
        setIframeSrc(data.redirect);
        if (!isBonus) dispatch(addRunningGamesId(gameId));
        return;
      }

      if (+data?.errorCode === 1) {
        setOpenLogin(true);
        return;
      }
      if (errorMes) setErrorMes(errorMes);
    };

    //Bonus
    if (isBonus) {
      const dataGameUrl = { gameId, mode, theme, madeOn, uid, lang: language };
      if (DEBUG) console.log("data bonus game", dataGameUrl);
      API_CASINO.getBonusGameUrl(dataGameUrl)
        .then((e) => resGame(e, isBonus))
        .catch(() => setErrorMes(true));

      return;
    }
    //no Bonus
    const dataGameUrl = { gameId, lang: language, mode, madeOn };
    if (DEBUG) console.log("data game", dataGameUrl);
    API_CASINO.getGameUrl(dataGameUrl)
      .then(resGame)
      .catch(() => setErrorMes(true));
  }, [errorMessage, isBonus, uid, theme, gameId, language, mode, justCame, madeOn]);

  return { onCloseGame, hideCross, iframeSrc };
}
