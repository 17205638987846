import React from "react";
import DefaultModal from "../../DefaultModal";
import { useTranslation } from "react-i18next";
import "./withdraw-result.scss";
import WithdrawInfoP from "../../../mui/withdraw_info_p/WithdrawInfoP";
import { useAppSelector } from "../../../../store/index.ts";
import ShowContainer from "../../../mui/ShowContainer.jsx";
import { useReadLocalStorage } from "usehooks-ts";
import { ENUM_LCS_NAME } from "../../../../store/enums.js";

export default function WithdrawSuccess({ isOpen, closeModal, info }) {
  const { t } = useTranslation();
  const start_r = useReadLocalStorage(ENUM_LCS_NAME.startR) || "";
  const supportChat = useAppSelector((state) => state.links.supportChat);

  const currency = info.currency;
  const requestNumber = info.invoiceNumber;
  const amount = info.amount;
  const commission = info.commission;
  const received = info.toReceive;
  const withdraw1 = "1 minutes";
  const withdraw2 = "24 hours";

  const tgLink = start_r
    ? `https://t.me/${supportChat}?start_r=${start_r}`
    : `https://t.me/${supportChat}`;

  return (
    <DefaultModal miniModal closeModal={closeModal} isOpen={isOpen} classModal="withdraw-success">
      <h2>
        {t("Application created")} <span className="accent">{requestNumber}</span>
        <br /> {t("for withdrawal")}{" "}
        <span className="accent">
          {amount} {currency}
        </span>
      </h2>
      <div className="withdraw-success__items">
        <WithdrawInfoP
          condition={commission}
          text={"Withdrawal fee: "}
          accent={`${commission} ${currency}`}
        />
        <WithdrawInfoP
          condition={received}
          text={"To be received: "}
          accent={`${received} ${currency}`}
        />
      </div>
      <div className="withdraw-success__info">
        <p>
          {t("Withdrawal is carried out within the period from")} <span>{withdraw1}</span>{" "}
          {t("before")} <span>{withdraw2}</span>
        </p>

        <ShowContainer condition={supportChat}>
          <p>
            {t("In case of waiting above")} <span>{withdraw2}</span> - {t("please contact support")}{" "}
            <a target="_blank" href={tgLink} className="withdraw-success__support" rel="noreferrer">
              @{supportChat}
            </a>
          </p>
        </ShowContainer>
      </div>
    </DefaultModal>
  );
}
