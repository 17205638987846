import { useEffect } from "react";
import { useAppSelector } from "../store/index.ts";
import { API_USER } from "../api/user.js";
import { setInfo } from "../store/userSlice.js";
import { useDispatch } from "react-redux";
import { useEventListener } from "usehooks-ts";
import { ENUM_COMMON_EVENT } from "./common/useListener.js";

export default function useFillUser() {
  const userId = useAppSelector((state) => state.user.userId);
  const dispatch = useDispatch();

  const getInfo = () => {
    if (!userId) return;
    API_USER.getProfile()
      .then((e) => {
        dispatch(setInfo(e.data));

        console.log(e);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getInfo();
  }, [userId]);

  useEventListener(ENUM_COMMON_EVENT.getInfo, () => getInfo());
}
