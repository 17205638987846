import { createSlice } from "@reduxjs/toolkit";

const promotionsSlice = createSlice({
  name: "links",
  initialState: {
    lang: "",
    supportChat: "",
    channelUrl: "",
    reviewUrl: "",
    chatUrl: "",
    tgPostUrl: ""
  },
  reducers: {
    setLinkLang(state, newAction) {
      state.lang = newAction.payload;
    },
    setSupportChat(state, newAction) {
      state.supportChat = newAction.payload;
    },
    setChannelUrl(state, newAction) {
      state.channelUrl = newAction.payload;
    },
    setReviewUrl(state, newAction) {
      state.reviewUrl = newAction.payload;
    },
    setChatUrl(state, newAction) {
      state.chatUrl = newAction.payload;
    },
    setTgPostUrl(state, newAction) {
      state.tgPostUrl = newAction.payload;
    },
    setSupportEmail(state, newAction) {
      state.supportEmail = newAction.payload;
    }
  }
});
export default promotionsSlice.reducer;

export const {
  setLinkLang,
  setSupportChat,
  setChannelUrl,
  setReviewUrl,
  setChatUrl,
  setTgPostUrl,
  setSupportEmail
} = promotionsSlice.actions;
