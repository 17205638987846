import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../store/index.ts";

export default function useGetLink(link) {
  const theme = useAppSelector((state) => state.common.botTheme);
  const mode = useAppSelector((state) => state.common.modeGame);
  const sessionId = useAppSelector((state) => state.common.sessionId);
  const lang = useAppSelector((state) => state.common.lang);
  const isTgBot = useAppSelector((state) => state.common.isTgBot);

  const location = useLocation();
  const searchParams = location.search;
  const hash = location.hash;
  const urlParams = new URLSearchParams(searchParams);
  if (lang) urlParams.set("lang", lang);
  if (theme) urlParams.set("theme", theme);
  if (mode) urlParams.set("mode", mode);
  if (sessionId) urlParams.set("sessionId", sessionId);

  return isTgBot ? `${link}?${urlParams.toString()}${hash}` : `${link}${hash}`;
}
