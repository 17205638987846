import React, { useEffect, useState } from "react";
import "./login-modal.scss";
import DefaultModal from "../DefaultModal";
import LoginImg from "./LoginImg";
import LoginFields from "./LoginFields";
import RecoveryPas from "./recovery-pas/RecoveryPas";
import { useAppSelector } from "../../../store/index.ts";

export default function LoginModal({ isOpen, closeModal, data: dataLogin, hideCross }) {
  const lockLoginClose = useAppSelector((state) => state.site.lock.loginClose);
  const [forgotPas, setForgotPas] = useState(false);
  const classModal = forgotPas ? "recovery-pas" : "login-modal";

  useEffect(() => {
    setForgotPas(false);
  }, [isOpen]);

  return (
    <DefaultModal
      classModal={classModal}
      isOpen={isOpen}
      closeModal={!lockLoginClose && closeModal}
      hideClose={hideCross || lockLoginClose}>
      {forgotPas ? (
        <RecoveryPas setForgotPas={setForgotPas} />
      ) : (
        <>
          <LoginImg />
          <LoginFields setForgotPas={setForgotPas} dataLogin={dataLogin} closeModal={closeModal} />
        </>
      )}
    </DefaultModal>
  );
}
