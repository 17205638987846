import React from "react";
import imgRegisterGirl from "./register-girl.png";
import imgLoginGirl from "./login-girl.png";
import useDynamicImages from "../../../hooks/useDynamicImages.js";
import { useSelector } from "react-redux";
import { ENUM_LOGIN } from "../../../store/enums";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/index.ts";

export default function LoginImg() {
  const { t } = useTranslation();
  const casinoName = useSelector((state) => state.common.casinoName);
  const typeS = useAppSelector((state) => state.site.modals?.data?.login?.type);
  const typeLogin = typeS || ENUM_LOGIN.register;

  const { imgSignup, imgLogin } = useDynamicImages();

  const dynamicImgByType = {
    [ENUM_LOGIN.login]: imgLogin,
    [ENUM_LOGIN.register]: imgSignup
  };

  const imgSourceByType = {
    [ENUM_LOGIN.login]: imgLoginGirl,
    [ENUM_LOGIN.register]: imgRegisterGirl
  };

  const dynamicImg = dynamicImgByType[typeLogin];

  return dynamicImg ? (
    <div
      className="login-img dynamic-img-container"
      style={{ backgroundImage: `url("${dynamicImg}")` }}>
      {/* <img src={dynamicImg} className="blur" /> */}
      {/* <img src={dynamicImg} alt={typeLogin} /> */}
    </div>
  ) : (
    <div className="login-img">
      <div className="login-img__text">
        <span className="login-img__welcome">{t("Welcome to")}</span>
        <span className="login-img__name">{casinoName}</span>
      </div>
      <img src={imgSourceByType[typeLogin]} />
    </div>
  );
}
