import { cusAxios, PREFIX } from "./main";
import { checkUrlBotTg, ENUM_MADE_ON } from "../store/enums";
import axios from "axios";
import { getLang } from "../utils/utils";

const madeOn = checkUrlBotTg() ? ENUM_MADE_ON.tgBot : ENUM_MADE_ON.site;

const loginTelegram = async (data) => {
  const lang = getLang();

  let response = await cusAxios({
    method: "POST",
    url: `${PREFIX}/api/gamers/login_telegram`,
    data: { ...data, madeOn, lang }
  });
  return response;
};

const loginEmail = async (data) => {
  const lang = getLang();

  let response = await cusAxios({
    method: "POST",
    url: `${PREFIX}/api/gamers/login_email`,
    data: { ...data, madeOn, lang }
  });
  return response;
};

const userBalance = async (data) => {
  const lang = getLang();

  let response = await axios({
    method: "POST",
    url: `${PREFIX}/api/gamers/user_balance`,
    data: { ...data, madeOn, lang }
  });
  return response;
};

const balance = async () => {
  const lang = getLang();

  let response = await axios({
    method: "POST",
    url: `${PREFIX}/api/gamers/user_all_balances`,
    data: { madeOn, lang }
  });
  return response;
};

const changePassword = async (data) => {
  const lang = getLang();

  let response = await axios({
    method: "POST",
    url: `${PREFIX}/api/gamers/change_password`,
    data: { madeOn, lang, ...data }
  });
  return response;
};

const changeEmail = async (data) => {
  const lang = getLang();

  let response = await axios({
    method: "POST",
    url: `${PREFIX}/api/gamers/change_email`,
    data: { madeOn, lang, ...data }
  });
  return response;
};

const repeatVerifyCode = async () => {
  const lang = getLang();

  let response = await axios({
    method: "POST",
    url: `${PREFIX}/api/gamers/verify_email`,
    data: { madeOn, lang }
  });
  return response;
};

const logout = async (data) => {
  const lang = getLang();

  let response = await cusAxios({
    method: "POST",
    url: `${PREFIX}/api/gamers/logout`,
    data: { ...data, madeOn, lang }
  });
  return response;
};
const depositLimit = async (data) => {
  const lang = getLang();

  let response = await axios({
    method: "POST",
    url: `${PREFIX}/api/gamers/deposit_limit`,
    data: { ...data, madeOn, lang }
  });
  return response;
};
const getDepositLockInfo = async () => {
  const lang = getLang();

  let response = await axios({
    method: "POST",
    url: `${PREFIX}/api/gamers/deposit_lock_info`,
    data: { madeOn, lang }
  });
  return response;
};
const depositMethod = async ({ amount, currency }) => {
  const lang = getLang();

  let response = await axios({
    method: "POST",
    url: `${PREFIX}/api/gamers/deposit_method`,
    data: { amount, currency, madeOn, lang }
  });
  return response;
};
const makeDeposit = async ({ amount, currency, method }) => {
  const lang = getLang();

  let response = await cusAxios({
    method: "POST",
    url: `${PREFIX}/api/gamers/make_deposit`,
    data: { amount, currency, method, madeOn, lang }
  });
  return response;
};

const getAddressCrypto = async (data) => {
  const lang = getLang();

  let response = await cusAxios({
    method: "POST",
    url: `${PREFIX}/api/gamers/get_address`,
    data: { ...data, madeOn, lang }
  });
  return response;
};

const changeCurrency = async (data) => {
  const lang = getLang();

  let response = await cusAxios({
    method: "POST",
    url: `${PREFIX}/api/gamers/change_currency`,
    data: { ...data, madeOn, lang }
  });
  return response;
};

const payoutInfo = async () => {
  const lang = getLang();

  let response = await axios({
    method: "POST",
    url: `${PREFIX}/api/gamers/payout_info`,
    data: { madeOn, lang }
  });
  return response;
};

const promocode = async (data) => {
  const lang = getLang();

  let response = await cusAxios({
    method: "POST",
    url: `${PREFIX}/api/gamers/use_promocode`,
    data: { madeOn, ...data, lang }
  });
  return response;
};

const makePayout = async (data) => {
  const lang = getLang();

  let response = await cusAxios({
    method: "POST",
    url: `${PREFIX}/api/gamers/make_payout`,
    data: { madeOn, ...data, lang }
  });
  return response;
};

const toggleFavorites = async (gameId) => {
  const lang = getLang();

  let response = await cusAxios({
    method: "POST",
    url: `${PREFIX}/api/gamers/toggle_favorites`,
    data: { madeOn, gameId, lang }
  });
  return response;
};
const clearBonus = async () => {
  const lang = getLang();

  let response = await cusAxios({
    method: "POST",
    url: `${PREFIX}/api/gamers/clear_bonus`,
    data: { madeOn, lang }
  });
  return response;
};
const resetPas = async () => {
  const lang = getLang();

  let response = await cusAxios({
    method: "POST",
    url: `${PREFIX}/api/gamers/reset_password`,
    data: { madeOn, lang }
  });
  return response;
};

const confirmEmail = async ({ code }) => {
  const lang = getLang();

  let response = await cusAxios({
    method: "POST",
    url: `${PREFIX}/api/gamers/confirm_email`,
    data: { madeOn, lang, code }
  });
  return response;
};

const getProfile = async () => {
  const lang = getLang();

  let response = await cusAxios({
    method: "POST",
    url: `${PREFIX}/api/gamers/user_profile`,
    data: { madeOn, lang }
  });
  return response;
};

export const API_USER = {
  login: {
    telegram: loginTelegram,
    email: loginEmail
  },
  logout,
  balance: {
    info: balance,
    userBalance: userBalance,
    limit: depositLimit,
    replenish: makeDeposit,
    getCryptoAddress: getAddressCrypto,
    method: depositMethod,
    getDepositLockInfo,
    payout: {
      info: payoutInfo,
      make: makePayout
    },
    clearBonus: clearBonus
  },
  getProfile,
  changeCurrency,
  changePassword,
  changeEmail,
  toggleFavorites,
  resetPas,
  confirmEmail,
  repeatVerifyCode,
  promocode
};
