import { useMemo } from "react";
import { useAppSelector } from "../store/index.ts";
import { ENUM_BALANCE_TYPE } from "../store/enums";

/**
 * @description - возвращает баланс в текущей валюте
 */
export default function useCurrentBalance() {
  const currency = useAppSelector((state) => state.user.currency);
  const balanceAll = useAppSelector((state) => state.user.balance);

  const balances = balanceAll?.[currency]?.balances;
  const info = balanceAll?.[currency]?.info;

  const isCrypto = useMemo(() => info?.type === ENUM_BALANCE_TYPE.crypto, [info]);

  return { balances, info, isCrypto };
}
