import React, { useEffect, useState } from "react";
import imgCardCards from "./type_image/cards.png";
import imgCarRub from "./type_image/rub.png";
import imgCardSber from "./type_image/sberPay.png";
import imgCardSbp from "./type_image/sbp.png";
import imgCardUsdt from "./type_image/usdt.png";
import imgCardHumo from "./type_image/humo.png";
import imgCardUzcard from "./type_image/uzcard.png";
import imgQrcode from "./type_image/qrcode.png";
import "./top-up-cards.scss";
import { API_USER } from "../../../../api/user";
import { errorToast } from "../../../mui/Toaster";
import { DEBUG } from "../../../../store/enums";
import { messageByCode } from "../../../../utils/utils";
import { useTranslation } from "react-i18next";
import LoaderContainer from "../../../mui/loader_container/LoaderContainer";

import { useAppSelector } from "../../../../store/index.ts";
import { setLockBalanceClose } from "../../../../store/siteSlice.js";
import { useDispatch } from "react-redux";

export default function TopUpCards({
  amount,
  currency,
  closeModal,
  setLockedInfo,
  setOpenPaymentPhone,
  setOpenPaymentCard
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isIos = useAppSelector((state) => state.deviceSize.isIos);
  const [cards, setCard] = useState([]);
  const [loader, setLoader] = useState(false);

  const cardsByIdCommon = {
    sber: { id: "sber", img: imgCardSber, title: "To Sberbank account" },
    sbp: { id: "sbp", img: imgCardSbp, title: "SBP by phone number" },
    rub: { id: "rub", img: imgCarRub, title: "Transfer to account" },
    usdt: { id: "usdt", img: imgCardUsdt, title: "USDT" },
    qrc: { id: "qrc", img: imgQrcode, title: "QR code" }
  };

  const cardsById = {
    ...cardsByIdCommon,
    card: { id: "card", img: imgCardCards, title: "Transfer to bank card" },
    humo: { id: "humo", img: imgCardHumo, title: "Humo" },
    uzcard: { id: "uzcard", img: imgCardUzcard, title: "Uzcard" }
  };

  const cardsByIdUZS = {
    ...cardsByIdCommon
  };

  useEffect(() => {
    if (!loader && cards.length == 0) {
      dispatch(setLockBalanceClose(false));
    }
  }, [cards, loader]);

  /**
   * @description - получение списка методов
   */
  useEffect(() => {
    setLoader(true);
    API_USER.balance
      .method({ amount, currency })
      .then((e) => {
        if (DEBUG) console.log(e);
        if (currency === "UZS") {
          const newCardsDop = e.data.list?.map((el) => cardsByIdUZS[el]);
          const newCards = [];

          newCards.push({ ...cardsById.humo, id: "card" });
          newCards.push({ ...cardsById.uzcard, id: "card" });
          newCards.push(...newCardsDop);
          setCard(newCards.filter((el) => el) || []);
          return;
        }

        if (e?.dataError) return errorToast(messageByCode(e.dataError));
        const cards = e.data?.list?.map((el) => cardsById[el]) || [];
        setCard(cards.filter((el) => el) || []);
      })
      .catch(console.error)
      .finally(() => {
        setLoader(false);
      });
  }, [amount, currency]);

  /**
   * @description - выбор нужного метода
   */
  const onCardClick = (method) => {
    // window.open("https://www.tesla.com/de_de", "_blank");
    setLoader(true);

    API_USER.balance
      .replenish({ amount, currency, method })
      .then((e) => {
        // isIos
        //   ? (window.location.href = "https://www.tesla.com/de_de")
        //   : window.open("https://www.tesla.com/de_de", "_blank");

        if (DEBUG) console.log("make deposit res", e);
        const data = e.data;

        if (data.depositLockInfo) setLockedInfo(data);
        if (data?.dataError) return errorToast(messageByCode(data.dataError));
        if (data?.error)
          return errorToast(t("It was not possible to issue payment details. Try again."));
        // if (data?.paymentUrl) setUrl(data.paymentUrl);
        if (data?.paymentUrl) {
          isIos ? (window.location.href = data.paymentUrl) : window.open(data.paymentUrl, "_blank");

          closeModal();
        }

        if (data.phonePayment) setOpenPaymentPhone({ ...data.phonePayment, sum: amount, currency });
        if (data.cardPayment) setOpenPaymentCard({ ...data.cardPayment, sum: amount, currency });
      })
      .catch((e) => {
        if (DEBUG) console.log("make deposit cath", e);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <LoaderContainer loader={loader} center>
      {cards?.length ? (
        <div className="top-up-balance__cards">
          {cards.map((card, indx) => (
            <div key={indx} className="card" onClick={() => onCardClick(card.id)}>
              <img src={card.img} />
              <span>{t(card.title)}</span>
            </div>
          ))}
        </div>
      ) : (
        <div className="top-up-balance__cards__empty">
          <h3>{t("No methods available")}</h3>
        </div>
      )}
    </LoaderContainer>
  );
}
