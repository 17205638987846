import React, { useEffect, useState } from "react";
import CusForm from "../../mui/CusForm";
import EmailInput from "../../mui/EmailInput";
import CusButton from "../../mui/custom_button/CusButton";
import { API_USER } from "../../../api/user";
import { errorToast, infoToast } from "../../mui/Toaster";
import { messageByCode } from "../../../utils/utils";
import { useDispatch } from "react-redux";
import { setInfo } from "../../../store/userSlice";
import { ENUM_ACCOUNT_REGISTER } from "../../../store/enums";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/index.ts";
import iconVerified from "./verified.png";
import iconUnverified from "./unverified.png";

export default function ProfileNewEmail() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const email = useAppSelector((state) => state.user.info?.email) || "test@test.com";
  const emailConfirmed = useAppSelector((state) => state.user.info?.emailConfirmed);
  const [alreadyChange, setAlreadyChange] = useState(false);
  const [isVerifyRepeat, setIsVerifyRepeat] = useState(false);

  useEffect(() => {
    setAlreadyChange(false);
    setIsVerifyRepeat(false);
  }, []);

  const onEmailSubmit = (_, fields) => {
    const newEmail = fields.newEmail;

    if (newEmail === email) {
      if (emailConfirmed) return infoToast(t("The email has already been confirmed."));
      runVerifyRepeat();
      return;
    }

    API_USER.changeEmail(fields)
      .then((e) => {
        const dataError = e.data.dataError;
        const error = e.error;

        if (dataError) return errorToast(t(messageByCode(dataError)));
        if (error) errorToast(error);

        dispatch(
          setInfo({
            nickname: newEmail,
            email: newEmail,
            register: ENUM_ACCOUNT_REGISTER.email
          })
        );
        setIsVerifyRepeat(false);
        setTimeout(() => setAlreadyChange(true), 0);
        // setTimeout(() => closeModal(), 1000);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const runVerifyRepeat = () => {
    API_USER.repeatVerifyCode()
      .then((e) => {
        console.log(e);
        setIsVerifyRepeat(true);
        setAlreadyChange(true);
        // setTimeout(() => closeModal(), 1000);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <CusForm className="email" onSubmit={onEmailSubmit}>
      <p>{t("Your email")}</p>
      <EmailInput
        name="newEmail"
        defaultValue={email}
        iconSourceEnd={emailConfirmed ? iconVerified : iconUnverified}
        sourceEndText={emailConfirmed ? "Verified" : "Not verified"}
      />
      {alreadyChange ? (
        <div className="already-text">
          <p>
            {isVerifyRepeat
              ? t("A link with an email confirmation code has been sent to the specified email")
              : t("A link has been sent to newMail to confirm the email (and login) change.", {
                  newMail: email
                })}
          </p>
        </div>
      ) : (
        <div className="buttons">
          {emailConfirmed ? (
            <CusButton border text={"Change e-mail"} color="gradient-blue" />
          ) : (
            <CusButton border text={"Send verification code"} disable={isVerifyRepeat} />
          )}
        </div>
      )}
    </CusForm>
  );
}
