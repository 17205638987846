import { createSlice } from "@reduxjs/toolkit";
import { getParamFromUrl } from "../utils/utils";
import { checkUrlBotTg, ENUM_ACCOUNT_REGISTER, ENUM_LCS_NAME } from "./enums";

const userId = getParamFromUrl("userId") || localStorage.getItem(ENUM_LCS_NAME.userId);

// - основная информация стора о сайте
const commonSlice = createSlice({
  name: "user",
  initialState: {
    userId: userId,
    isAuth: userId || checkUrlBotTg(),
    currency: "",
    info: {
      login: "",
      photoUrl: "",
      username: "",
      email: "",
      regType: ENUM_ACCOUNT_REGISTER.noRegister,
      telegramName: "",
      emailConfirmed: false
    },
    currencies: {},
    currencyInfo: {},
    balance: {}
  },
  reducers: {
    setInfo(state, newAction) {
      const info = { ...state.info, ...newAction.payload };
      state.info = info;
    },
    setBalance(state, newAction) {
      state.balance = newAction.payload;
    },
    setUserId(state, newAction) {
      const userId = newAction.payload;
      userId
        ? localStorage.setItem(ENUM_LCS_NAME.userId, userId)
        : localStorage.removeItem(ENUM_LCS_NAME.userId);
      state.userId = userId;
      state.isAuth = !!userId;
    },
    setCurrency(state, newAction) {
      state.currency = newAction.payload;
    }
  }
});

export default commonSlice.reducer;

export const { setInfo, setSessionId, setUserId, setCurrency, setBalance } = commonSlice.actions;
