import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocalStorage } from "usehooks-ts";
import ShowContainer from "../ShowContainer";
import TelegramLoginButton from "telegram-login-button";
import { API_USER } from "../../../api/user";
import { errorToast, successToast } from "../Toaster";
import { DEBUG, ENUM_ACCOUNT_REGISTER, ENUM_LCS_NAME } from "../../../store/enums";
import { setInfo, setUserId } from "../../../store/userSlice";
import { ENUM_COMMON_EVENT } from "../../../hooks/common/useListener";
import { dispatchCusEvent } from "../../../utils/utils";

export default function AuthTg({ closeModal, successCb }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [, setAlreadyAccount] = useLocalStorage(ENUM_LCS_NAME.alreadyAccount);

  const botName = useSelector((state) => state.common.botName);
  const telegramWidget = useSelector((state) => state.common.telegramWidget);

  const [startR] = useLocalStorage(ENUM_LCS_NAME.startR, "");
  const [pbParams] = useLocalStorage(ENUM_LCS_NAME.pbParams, "");

  return (
    <ShowContainer condition={telegramWidget && botName}>
      <TelegramLoginButton
        cornerRadius={8}
        botName={botName}
        dataOnauth={(user) => {
          if (DEBUG) console.log("dataOnauth", user);
          const domain = window.location.hostname;

          const params = {
            ...user,
            domain: domain,
            start_r: startR,
            pb_params: pbParams
          };
          if (DEBUG) console.log("params", params);
          try {
            API_USER.login
              .telegram(params)
              .catch(() => {
                errorToast(t("Authorization failed!"));
              })
              .then((e) => {
                console.log("res loginTelegram", e);
                const user_id = e.data?.user_id;
                if (DEBUG && e.data.error) console.log("error", e.data.error);
                if (!user_id) return errorToast(t("Authorization failed!"));

                dispatch(setUserId(user_id));
                successToast(t("Authorization successful!"));
                setAlreadyAccount(true);

                const info = {
                  register: ENUM_ACCOUNT_REGISTER.telegram,
                  firstName: user.first_name,
                  lastName: user.last_name,
                  photoUrl: user.photo_url,
                  nickname: user.username || user.first_name || user.id
                };

                dispatch(setInfo(info));
                setTimeout(() => {
                  dispatchCusEvent(ENUM_COMMON_EVENT.getBalance);
                }, 100);

                successCb?.();
                closeModal(true);
              });
          } catch (e) {
            console.log(e);
          }
        }}
      />
    </ShowContainer>
  );
}
