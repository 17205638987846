import React from "react";
import CusInput from "./custom_input/CusInput";
import IconMail from "./JSXIcons/IconMail";
import { API_CASINO } from "../../api/casino";
import { dispatchCusEvent } from "../../utils/utils";
import { useTranslation } from "react-i18next";
import { useReadLocalStorage } from "usehooks-ts";
import { ENUM_LCS_NAME } from "../../store/enums";

export default function EmailInput({
  errorWarning,
  successInfo,
  name = "email",
  placeholder = "Email",
  required = true,
  iconSourceEnd,
  sourceEndText,
  defaultValue
}) {
  const { t } = useTranslation();
  const start_r = useReadLocalStorage(ENUM_LCS_NAME.startR) || "";

  const onEmailBlur = (event) => {
    const target = event.target;
    const value = target.value;
    const domain = value?.split("@")?.[1];
    API_CASINO.checkEmailDomain({ domain, start_r })
      .then((e) => {
        console.log(e);
        const data = e.data;
        if (Object.prototype.hasOwnProperty.call(data, "valid")) {
          if (!data.valid) {
            if (target.dataset.error) return;
            if (!target.value) return;
            const message = t(
              "The email address may be incorrect. Check the domain of the email address carefully."
            );
            dispatchCusEvent("inputError", { detail: { message, target } });
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <CusInput
      name={name}
      placeholder={placeholder}
      autocomplete="email"
      required={required}
      iconJSXstart={<IconMail />}
      dataType={"email"}
      defaultValue={defaultValue}
      onBlur={onEmailBlur}
      errorWarning={errorWarning}
      successInfo={successInfo}
      iconSourceEnd={iconSourceEnd}
      sourceEndText={sourceEndText}
    />
  );
}
