import React from "react";
import DefaultModal from "../DefaultModal";
import imgLock from "./banner-lock.png";
import "./show-new-pas.scss";
import { useTranslation } from "react-i18next";
import CusButton from "../../mui/custom_button/CusButton";
import CusInput from "../../mui/custom_input/CusInput";
import CloseModal from "../../mui/close_modal/CloseModal";

export default function ShowNewPas({ isOpen, shownNewPas, closeModal }) {
  const { t } = useTranslation();
  return (
    <DefaultModal
      isOpen={isOpen}
      miniModal
      closeModal={closeModal}
      classModal="show-new-pas__container">
      <div className="show-new-pas">
        <CloseModal close={closeModal} />
        <div className="show-new-pas__img">
          <img src={imgLock} height={120} />
        </div>
        <div className="show-new-pas__fields">
          <h2>{t("New password set")}</h2>
          <div className="show-new-pas__fields__forms">
            <CusInput readOnly isCopy defaultValue={shownNewPas} />

            <CusButton className="remind-submit" text={"Copy"} color="gradient-blue" />
          </div>
        </div>
      </div>
    </DefaultModal>
  );
}
