import React from "react";
import { useTranslation } from "react-i18next";
import IconBonus from "../../mui/JSXIcons/IconBonus";

import "./static-bonus.scss";
import StaticBonusItem from "./StaticBonusItem";
import useFillBonus from "../../../hooks/common/use_fill_bonus/useFillBonus";
import LoaderContainer from "../../mui/loader_container/LoaderContainer";
import { useAppSelector } from "../../../store/index.ts";

export default function StaticBonus() {
  const { t } = useTranslation();
  const { bonuses } = useFillBonus();
  const forUser = useAppSelector((state) => state.promotions.forUser);

  return (
    <div className="static-bonus">
      <h3>
        <IconBonus /> {t("Bonuses")}
      </h3>

      <LoaderContainer loader={forUser === "loading"} center>
        <div className="static-bonus__bonuses">
          {bonuses.map((el) => (
            <StaticBonusItem bonus={el} key={el.id} />
          ))}
        </div>
      </LoaderContainer>
    </div>
  );
}
