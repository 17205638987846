import React from "react";
import "./withdrawal-limitation.scss";
import DefaultModal from "../DefaultModal";
import { useTranslation } from "react-i18next";
import imgUnverified from "../profile/unverified.png";
import CusButton from "../../mui/custom_button/CusButton";
import { useDispatch } from "react-redux";
import { setModalProfile } from "../../../store/siteSlice";

export default function WithdrawalLimitationModal({ isOpen, closeModal }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const openProfile = () => {
    closeModal();
    dispatch(setModalProfile(true));
  };

  return (
    <DefaultModal
      miniModal
      isOpen={isOpen}
      closeModal={closeModal}
      classModal="withdraw-limitation-modal">
      <h2>{t("Withdrawal is limited!")}</h2>
      <div className="withdraw-limitation__content">
        <div className="withdraw-limitation__info">
          <div className="withdraw-limitation__info__text">
            <img src={imgUnverified} alt="unverified" className="withdraw-limitation__info__img" />
            <p>{t("To remove restrictions, confirm your email address!")}</p>
          </div>
          <div className="withdraw-limitation__info__container">
            <button onClick={openProfile}>{t("Click here")}</button>
            <p className="withdraw-limitation__info__text__p">
              <span>{t("to confirm your email!")}</span>
            </p>
          </div>
        </div>
        <CusButton
          onClick={openProfile}
          className="open-profile"
          text={"Open profile"}
          color="gradient-blue"
        />
      </div>
    </DefaultModal>
  );
}
