import { useAppSelector } from "../store/index.ts";

export default function useDynamicImages() {
  const imgBonusMainDesktop = useAppSelector((state) => state.common.images?.main?.desktop);
  const imgBonusMainMobile = useAppSelector((state) => state.common.images?.main?.mobile);
  const imgBonusMenuDesktop = useAppSelector((state) => state.common.images?.menu?.desktop);
  const imgBonusMenuMobile = useAppSelector((state) => state.common.images?.menu?.mobile);
  const imgSignupDesktop = useAppSelector((state) => state.common.images?.signup?.desktop);
  const imgSignupMobile = useAppSelector((state) => state.common.images?.signup?.mobile);
  const imgLoginDesktop = useAppSelector((state) => state.common.images?.login?.desktop);
  const imgLoginMobile = useAppSelector((state) => state.common.images?.login?.mobile);

  const isExtraSmall = useAppSelector((state) => state.deviceSize.isExtraSmall);
  const isMobileSize = useAppSelector((state) => state.deviceSize.isMobileSize);
  const isMenuAbsolute = useAppSelector((state) => state.deviceSize.isMenuAbsolute);

  const imgBonusMenu = isMenuAbsolute ? imgBonusMenuMobile : imgBonusMenuDesktop;
  const imgBonusMain = isExtraSmall ? imgBonusMainMobile : imgBonusMainDesktop;
  const imgSignup = isMobileSize ? imgSignupMobile : imgSignupDesktop;
  const imgLogin = isMobileSize ? imgLoginMobile : imgLoginDesktop;

  return { imgBonusMain, imgBonusMenu, imgSignup, imgLogin };
}
