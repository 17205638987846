import { useLocation } from "react-router-dom";
import { getParamFromUrl } from "../../utils/utils";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setShownNewPas } from "../../store/siteSlice";
import { setInfo, setUserId } from "../../store/userSlice";
import { ENUM_ACCOUNT_REGISTER } from "../../store/enums";

export const FLAG_NEW_PAS = "new_pas";

export default function useNewPasCheck() {
  const location = useLocation();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   const newPas = getParamFromUrl(FLAG_NEW_PAS);

  //   if (!newPas) return;
  //   console.log("newPas", newPas);

  //   dispatch(setUserId(true));
  //   const info = {
  //     register: ENUM_ACCOUNT_REGISTER.email,
  //     firstName: "",
  //     lastName: "",
  //     photoUrl: "",
  //     nickname: ""
  //   };

  //   dispatch(setInfo(info));
  //   dispatch(setShownNewPas(newPas));
  // }, [location]);
}
