import { useAppSelector } from "../store/index.ts";

export default function useNickname() {
  const login = useAppSelector((state) => state.user.info.login);
  const telegramName = useAppSelector((state) => state.user.info.telegramName);
  const email = useAppSelector((state) => state.user.info.email);
  // const nickname = login || email || telegramName || "Nickname";
  const nickname = email || telegramName || "Nickname";
  return nickname.includes("@") ? nickname : `@${nickname}`;
}
