import { createSlice } from "@reduxjs/toolkit";
import {
  ENUM_DEVICE_ORIENTATION,
  EnumDeviceModes,
  EXTRA_SMALL,
  MOBILE_SIZE,
  SIZE_BIG_DESKTOP,
  SIZE_LSM,
  SIZE_MENU_ABSOLUTE,
  SIZE_SMALL_DESKTOP,
  SIZE_TABLET
} from "./enums";

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
function iOS() {
  return (
    ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(
      navigator?.platform || ""
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

function isUserOnTelegram() {
  return navigator.userAgent.includes("Telegram") || typeof TelegramWebviewProxy !== "undefined";
}

const isWebRTC = !!(
  navigator.getUserMedia ||
  navigator.webkitGetUserMedia ||
  navigator.mozGetUserMedia ||
  navigator.msGetUserMedia
);
const isTouchDevice = !!("ontouchstart" in window || navigator.maxTouchPoints);

const checkAppMode = (width = window.innerWidth, angle = screen?.orientation?.angle) => {
  if (angle == 90) return width <= MOBILE_SIZE ? EnumDeviceModes.desktop : EnumDeviceModes.mobile;

  return width <= MOBILE_SIZE ? EnumDeviceModes.mobile : EnumDeviceModes.desktop;
};

const getOrientation = (angle = screen?.orientation?.angle) => {
  if (angle === 90) return ENUM_DEVICE_ORIENTATION.landscape;
  if (angle === 0) return ENUM_DEVICE_ORIENTATION.portrait;
};

const commonSlice = createSlice({
  name: "deviceSize",
  initialState: {
    appMode: checkAppMode(),
    width: window.innerWidth,
    fullWidth: window.outerWidth,
    isBigDesktop: window.innerWidth > SIZE_BIG_DESKTOP,
    isMenuAbsolute: window.innerWidth < SIZE_MENU_ABSOLUTE,
    isSmallDesktop: window.innerWidth < SIZE_SMALL_DESKTOP,
    isDesktop: window.innerWidth > SIZE_TABLET,
    isLsm: window.innerWidth < SIZE_LSM,
    isMobileSize: window.innerWidth < MOBILE_SIZE,
    isExtraSmall: window.innerWidth < EXTRA_SMALL,
    isMobile: checkAppMode() === EnumDeviceModes.mobile,
    height: window.innerHeight,
    isSafari: isSafari,
    isIos: iOS(),
    isWebApps: isUserOnTelegram(),
    isWebRTC: isWebRTC,
    orientation: getOrientation(),
    angle: screen?.orientation?.angle,
    isTouch: isTouchDevice
  },
  reducers: {
    setWidth(state, newAction) {
      const width = newAction.payload;
      state.appMode = checkAppMode(width);
      state.isMobile = checkAppMode(width) === EnumDeviceModes.mobile;
      state.width = width;
      state.isBigDesktop = width > SIZE_BIG_DESKTOP;
      state.isMenuAbsolute = width <= SIZE_MENU_ABSOLUTE;
      state.isSmallDesktop = width <= SIZE_SMALL_DESKTOP;
      state.isDesktop = width > SIZE_TABLET;
      state.isLsm = width < SIZE_LSM;
      state.isMobileSize = width <= MOBILE_SIZE;
      state.isExtraSmall = width <= EXTRA_SMALL;
    },
    setAngle(state, newAction) {
      const angle = newAction.payload;
      state.angle = angle;
      state.orientation = getOrientation(angle);
      state.appMode = checkAppMode(window.innerWidth, angle);
      state.isMobile = checkAppMode(window.innerWidth, angle) === EnumDeviceModes.mobile;
    },
    setFullWidth(state, newAction) {
      state.fullWidth = newAction.payload;
    }
  }
});

export default commonSlice.reducer;

export const { setWidth, setAngle, setFullWidth } = commonSlice.actions;
