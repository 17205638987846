import { useEventListener } from "usehooks-ts";
import { useAppSelector } from "../../store/index.ts";

export const ENUM_TAWK_API = {
  show: "show",
  hide: "hide"
};

export default function useTawkController() {
  const isRunningGame = useAppSelector((state) => state.site.isRunningGame);

  const controller = window.Tawk_API;

  useEventListener(ENUM_TAWK_API.show, () => {
    if (isRunningGame) return;
    controller?.showWidget?.();
  });
  useEventListener(ENUM_TAWK_API.hide, () => {
    controller?.hideWidget?.();
  });
}
