import React, { useRef, useState } from "react";
import CloseModal from "../../../mui/close_modal/CloseModal";
import { useTranslation } from "react-i18next";
import { ReCAPTCHA } from "react-google-recaptcha";
import { SITE_KEY } from "../../../../store/enums";
import CusButton from "../../../mui/custom_button/CusButton";
import CusForm from "../../../mui/CusForm";
import "./recovery-pas.scss";
import imgLock from "./banner-lock.png";
import { API_USER } from "../../../../api/user";
import { useAppSelector } from "../../../../store/index.ts";
import EmailInput from "../../../mui/EmailInput.jsx";

export default function RecoveryPas({ setForgotPas }) {
  const { t } = useTranslation();
  const lang = useAppSelector((state) => state.common.lang);
  const captchaRef = useRef();
  const [disableBtn, setDisableBtn] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const onFormSubmit = (fields) => {
    const { email } = fields;

    // TODO API RESET PAS
    API_USER.resetPas({
      email,
      captcha: captchaRef.current
    })
      .then((e) => {
        const pwdSent = e.pwdSent;
        pwdSent
          ? setSuccess(
              `${t("Password change email sent.")} \n ${t("If you didn't see it in your Inbox, check your Spam folder.")}`
            )
          : setError(t("Email not found or not verified."));
      })
      .catch((e) => {
        console.log(e);
      });
    setDisableBtn(false);
  };

  return (
    <div className="recovery-pas">
      <div className="recovery-pas__img">
        <img src={imgLock} height={120} />
      </div>
      <div className="recovery-pas__fields">
        <CloseModal close={() => setForgotPas(false)} />
        <h2>{t("Forgot your password ?")}</h2>
        <p>{t("Enter the e-mail specified during registration")}</p>
        <CusForm className="recovery-pas__fields__forms" onSubmit={onFormSubmit}>
          <EmailInput errorWarning={error} successInfo={success} />

          <ReCAPTCHA ref={captchaRef} hl={lang} sitekey={SITE_KEY} />

          <CusButton
            className="remind-submit"
            text={"Set a new password"}
            type={"submit"}
            disable={disableBtn}
            color="gradient-blue"
            btnLoading={btnLoading}
            setBtnLoading={setBtnLoading}
          />
        </CusForm>
      </div>
    </div>
  );
}
