import React, { useRef } from "react";
import "./carousel-desk.scss";
import ShowContainer from "../ShowContainer.jsx";
import ReactOwlCarousel from "react-owl-carousel";
import useFixCarouselDesk from "../../../hooks/useFixCarouselDesk.js";
import CarouselDeskHeader from "./carousel_desk_header/CarouselDeskHeader.jsx";
import useGetItems from "../../../hooks/other/useGetItems.js";

export default function CarouselDesk({
  id,
  isRow2,
  items = [],
  title,
  children,
  showAllLink = ""
}) {
  const sliderRef = useRef(null);
  const slidesToShow = useGetItems();

  const manyLength = items.length > slidesToShow;

  useFixCarouselDesk({ sliderRef, children });

  return (
    <ShowContainer condition={items.length}>
      <div className="carousel-desk">
        <CarouselDeskHeader
          title={title}
          showAllLink={showAllLink}
          slidesToShow={slidesToShow}
          sliderRef={sliderRef}
          manyLength={manyLength}
        />
        {manyLength ? (
          <ReactOwlCarousel
            ref={sliderRef}
            startPosition={0}
            className="carousel-desk__items"
            margin={10}
            loop
            responsiveRefreshRate={0}
            mouseDrag={true}
            dots={false}
            items={slidesToShow}>
            {React.Children.map(children, (child, indx) => {
              if (isRow2) {
                return children;
              }

              return (
                <div
                  key={indx}
                  id={`${id}-item-${indx}`}
                  className="carousel-item"
                  data-index={indx}>
                  {child}
                </div>
              );
            })}
          </ReactOwlCarousel>
        ) : (
          <div className={`carousel-desk__items carousel-plug carousel-plug-${slidesToShow}`}>
            {children}
          </div>
        )}
      </div>
    </ShowContainer>
  );
}
